import Button from "antd/lib/button";
import Empty from "antd/lib/empty";
import Row from "antd/lib/row";
import Space from "antd/lib/space";
import { navigate } from "gatsby";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import ProductCard from "../components/ProductCard";
import ResponsiveTitle from "../components/ResponsiveTitle";

const Saved = () => {
  const { t } = useTranslation();
  const savedItems = useSelector((state) => state.saved) || [];

  const renderItems = () => {
    return savedItems.map((product) => {
      return <ProductCard product={product} key={product.id} />;
    });
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <ResponsiveTitle>{t("saved.savedItems")}</ResponsiveTitle>
      <Row justify="center">
        {savedItems.length ? (
          renderItems()
        ) : (
          <Empty description={t("saved.noItems")}>
            <Button onClick={() => navigate("/app/search")}>
              {t("saved.allProducts")}
            </Button>
          </Empty>
        )}
      </Row>
    </Space>
  );
};

export default Saved;
